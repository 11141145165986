import Header from 'components/Header'
import navData from 'components/Navigation/navigation.json'
import { validateHeaderItem } from 'utils/validations'

export default function HeaderContainer({
  content = {},
  customPhoneData = null,
  disabledElements,
  disabledRoutes,
  showProgressPage,
  specificHeaderCtaDataFiltered,
}) {
  const {
    headerCta = true,
    link = {},
    showNav = true,
    showPhone = true,
    showSignIn = true,
    signIn = {},
  } = content
  let linkEvent = link?.event
  let signInEvent = signIn?.event

  const mapContentfulToHeader = (data) => {
    if (!data?.navItems) return navData.mainNavigation
    return validateHeaderItem(data.navItems).map((navItem) => {
      if (navItem?.fields?.uiComponent === 'Grid') {
        return {
          label: navItem.fields.name,
          subNavMenus: [
            {
              menuTitle: '',
              menuItems: validateHeaderItem(navItem.fields.gridItems).map((subNav) => {
                return {
                  label: subNav.fields?.name,
                  description: subNav.fields?.linkText.content[0].content[0].value,
                  testid: subNav.fields?.linkText.content[0].content[0].value,
                  url: subNav.fields?.linkHref,
                }
              }),
            },
          ],
          testid: navItem.fields.name,
        }
      } else {
        return {
          label: navItem.fields.name,
          url: navItem.fields.linkHref,
          testid: navItem?.fields?.name,
        }
      }
    })
  }

  const mainNavigation = mapContentfulToHeader(content)

  return (
    <Header
      disabledElements={disabledElements}
      disabledRoutes={disabledRoutes}
      mainNavigation={mainNavigation}
      signInLinkUrl={signIn.fields?.linkHref}
      signInLinkText={signIn.fields?.linkText?.content[0]?.content[0]?.value}
      signInEvent={signInEvent}
      linkUrl={specificHeaderCtaDataFiltered ?? link.fields?.linkHref}
      linkText={link.fields?.linkText?.content[0]?.content[0]?.value}
      linkEvent={linkEvent}
      headerCta={headerCta}
      showNav={showNav}
      showSignIn={showSignIn}
      customPhoneData={customPhoneData}
      logoLink={content.logoLink ?? null}
      partnerLogo={content.partnerLogo?.fields ?? null}
      showPhone={showPhone}
      showProgressPage={showProgressPage}
    />
  )
}
